.loader {
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  background: rgba(255, 255, 255, 0.2);
  border-bottom: 0.8em solid rgba(255, 255, 255, 0.2);
  border-left: 0.8em solid #2e61a3;
  border-radius: 50%;
  border-right: 0.8em solid rgba(255, 255, 255, 0.2);
  border-top: 0.8em solid rgba(255, 255, 255, 0.2);
  height: 120px;
  overflow: hidden;
  position: relative;
  text-align: center;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  width: 120px;
  z-index: 1;

  &::after {
    border-radius: 50%;
    height: 120px;
    width: 120px;
  }
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
