#movieArea {
  background-image: url('/images/top/movie_thumb.png');
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.videoContainer {
  height: 100vh;
  min-width: 100%;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    height: calc(100vh + 120px);
    left: -200%;
    position: absolute;
    top: -60px;
    width: 500%;
  }
}
